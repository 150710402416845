import {
  type Assistant,
  type HumanMessage,
  type Message,
} from '@langchain/langgraph-sdk';
import { useStream } from '@langchain/langgraph-sdk/react';
import { useSearchParams } from '@remix-run/react';
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { type DtoGamePack } from '@lp-lib/api-service-client/public';
import { type CheckpointCommand } from '@lp-lib/shared-schema/src/agent/checkpoint';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { type FeatureQueryParamArrays } from '../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { err2s } from '../../../utils/common';
import { ThreadMessage, ThreadUserMessage } from '../../Agent/Message';
import { GraphUtils, useLoadAssistant } from '../../Agent/utils';
import { useUGCFileManager } from '../../Game/UGC/CustomGameFileManager';
import { CustomGamePackPromptEditor } from '../../Game/UGC/CustomGamePackPromptEditor';
import { Loading } from '../../Loading';
import { useUser } from '../../UserContext';
import { linkAgentThreadToGamePack } from '../utils';
import { type TrainingEditorControlAPI } from './TrainingEditorControlAPI';

type State = {
  messages: Message[];
  courseId: string | null;
  uid: string | null;
  config?: Record<string, unknown>;
};

type HumanMessageEx = HumanMessage & {
  additional_kwargs?: {
    checkpoint?: CheckpointCommand;
  };
};

function CheckpointMessage(props: {
  message: HumanMessageEx;
  rollbackCheckpoint: (refId: string) => Promise<void>;
}) {
  const { message, rollbackCheckpoint } = props;
  const checkpoint = message.additional_kwargs?.checkpoint;

  const {
    call: rollback,
    state: { state },
  } = useLiveAsyncCall(async () => {
    if (!checkpoint || !message.id) return;
    await rollbackCheckpoint(message.id);
  });

  if (checkpoint?.action !== 'create' || !message.id) return null;
  return (
    <div className='w-full flex items-center justify-center gap-1 border-b border-secondary'>
      <button
        type='button'
        className='text-xs text-secondary hover:text-primary'
        onClick={() => rollback()}
        disabled={state.isRunning}
      >
        Rollback to here {message.id.slice(0, 6)}
      </button>
      {state.isRunning && <Loading text='' imgClassName='w-3.5 h-3.5' />}
    </div>
  );
}

function MessageList(props: {
  stream: ReturnType<typeof useStream<State>>;
  rollbackCheckpoint: (refId: string) => Promise<void>;
}) {
  const { stream, rollbackCheckpoint } = props;
  const ref = useRef<HTMLDivElement>(null);

  const messagesCount = stream.messages.length;
  const lastMessage = stream.messages[stream.messages.length - 1];

  const scrollToBottom = useLiveCallback(() => {
    if (!ref.current) return;
    const scrollHeight = ref.current.scrollHeight;
    const height = ref.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    ref.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  });

  useLayoutEffect(() => scrollToBottom(), [scrollToBottom]);
  useLayoutEffect(
    () => scrollToBottom(),
    [
      messagesCount,
      scrollToBottom,
      lastMessage?.content?.length,
      stream.isLoading,
    ]
  );

  return (
    <div ref={ref} className='w-full flex-1 overflow-y-auto scrollbar my-4'>
      <div className='w-full min-h-full flex-1 flex flex-col gap-2 justify-end text-white px-2.5'>
        {stream.messages.map((message, idx) => {
          if (message.type === 'human') {
            return (
              <Fragment key={message.id}>
                <CheckpointMessage
                  message={message}
                  rollbackCheckpoint={rollbackCheckpoint}
                />
                <ThreadUserMessage message={message} />
              </Fragment>
            );
          }
          return (
            <ThreadMessage
              key={message.id}
              message={message}
              showLoading={
                stream.isLoading &&
                message.type === 'ai' &&
                idx === stream.messages.length - 1
              }
            />
          );
        })}
      </div>
    </div>
  );
}

function Main(props: {
  pack: DtoGamePack;
  ctrl: TrainingEditorControlAPI;
  assistant: Assistant;
  rebuildStores: () => Promise<void>;
  agentMode: FeatureQueryParamArrays['agentic'][number];
}) {
  const { pack, assistant, agentMode } = props;
  const user = useUser();
  const fileman = useUGCFileManager();
  const [searchParams, setSearchParams] = useSearchParams();

  const [threadId, setThreadId] = useState<string | null>(
    pack.ugcSettings?.trainingAgentThreadId ?? null
  );
  const rebuildStores = useDebouncedCallback(props.rebuildStores, 1000);

  const stream = useStream<State>({
    apiUrl: GraphUtils.API_URL,
    assistantId: assistant.assistant_id,
    threadId,
    onThreadId: (threadId) => {
      setThreadId(threadId);
    },
    onFinish: () => {
      props.rebuildStores();
    },
  });

  const lastMessage = stream.messages.at(stream.messages.length - 1);

  useEffect(() => {
    if (
      lastMessage &&
      lastMessage.type === 'tool' &&
      lastMessage.name?.match(/^create|update|delete|reorder/)
    ) {
      rebuildStores();
    }
  }, [lastMessage, rebuildStores]);

  const ensureThreadId = useLiveCallback(async (threadId: string) => {
    await linkAgentThreadToGamePack(pack, threadId);
  });

  const newHumanMessage = useLiveCallback(
    (message: string, checkpoint?: CheckpointCommand) => {
      return {
        type: 'human',
        content: message,
        additional_kwargs: {
          checkpoint,
        },
      } as HumanMessage;
    }
  );
  const submitFromOutline = useLiveCallback(async () => {
    const from = searchParams.get('from');
    if (from !== 'outline') return;
    searchParams.delete('from');
    setSearchParams(searchParams, { replace: true });
    stream.submit(
      {
        config: {
          ...stream.values.config,
          useCodeAgent: agentMode === 'code',
        },
        uid: user.id,
        courseId: pack?.id ?? null,
      },
      { onDisconnect: 'continue' }
    );
  });

  useLayoutEffect(() => {
    submitFromOutline();
  }, [submitFromOutline]);

  useEffect(() => {
    if (!threadId) return;
    ensureThreadId(threadId);
  }, [threadId, ensureThreadId]);

  useEffect(() => {
    fileman.init(pack.id, []);
    fileman.disable();
  }, [pack?.id, fileman]);

  const handleSubmit = useLiveCallback(async (message: string) => {
    if (stream.interrupt) {
      stream.submit(undefined, {
        command: { resume: message },
        onDisconnect: 'continue',
      });
    } else {
      stream.submit(
        {
          config: {
            ...stream.values.config,
            useCodeAgent: agentMode === 'code',
          },
          courseId: pack?.id ?? null,
          uid: user.id,
          messages: [newHumanMessage(message, { action: 'create' })],
        },
        { onDisconnect: 'continue' }
      );
    }
    return true;
  });

  const newThread = useLiveCallback(async () => {
    const client = GraphUtils.CreateClient();
    const thread = await client.threads.create();
    setThreadId(thread.thread_id);
  });

  const rollbackCheckpoint = useLiveCallback(async (refId: string) => {
    stream.submit(
      {
        config: {
          ...stream.values.config,
          useCodeAgent: agentMode === 'code',
        },
        courseId: pack?.id ?? null,
        uid: user.id,
        messages: [newHumanMessage('', { action: 'rollback', refId })],
      },
      { onDisconnect: 'continue' }
    );
  });

  return (
    <div className='w-full h-full flex flex-col gap-4'>
      <div className='w-full flex-none text-white text-sms flex justify-between items-center gap-2'>
        <div className='flex items-center gap-2'></div>
        <button type='button' className='btn text-primary' onClick={newThread}>
          Clear
        </button>
      </div>

      <MessageList stream={stream} rollbackCheckpoint={rollbackCheckpoint} />

      <div className='w-full flex-none'>
        <CustomGamePackPromptEditor
          enabled
          onSubmit={handleSubmit}
          onAbort={() => stream.stop()}
          isSubmitting={stream.isLoading}
          active
          autoFocus
          disableDeactivate
          bottomLabel=''
          placeholder='How can I help you today?'
          wrapperClassName='mb-4'
          width='w-full'
        />
      </div>
    </div>
  );
}

export function TrainingEditorAgentChatSidebar(props: {
  pack: DtoGamePack;
  ctrl: TrainingEditorControlAPI;
  rebuildStores: () => Promise<void>;
  agentMode: FeatureQueryParamArrays['agentic'][number];
}) {
  const {
    data: assistant,
    error,
    isLoading,
  } = useLoadAssistant('block-crafter');

  if (isLoading) return null;
  if (error) {
    return (
      <div className='text-red-002'>
        Error loading assistant: {err2s(error)}
      </div>
    );
  }
  if (!assistant) {
    return (
      <div className='text-red-002'>Assistant not found: {err2s(error)}</div>
    );
  }

  return (
    <Main
      pack={props.pack}
      ctrl={props.ctrl}
      assistant={assistant}
      rebuildStores={props.rebuildStores}
      agentMode={props.agentMode}
    />
  );
}
